<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->

							<article class="article-body" id="article-body" rel="image-enlarge">
								<p data-identifyelement="207">
									<strong data-identifyelement="208"><u data-identifyelement="209">Recommended Hardware</u></strong>
								</p>
								<p data-identifyelement="210">We recommend Android TV Boxes for the best experience.</p>
								<p data-identifyelement="211">
									Click here to view our list of recommended devices:
									<br />
									<router-link class="text-blue-500" :to="{ name: 'WhatHardwareIsRecommended' }"> What hardware is recommended ? </router-link>
								</p>
								<p data-identifyelement="216"><br data-identifyelement="217" /></p>
								<p data-identifyelement="218">
									<strong data-identifyelement="219"><u data-identifyelement="220">Installation Guides:</u></strong>
								</p>
								<p data-identifyelement="221">
									Choose your Device from the list below and follow our step by step installation guides based on what software/app&nbsp;
								</p>
								<p data-identifyelement="222">you want to use. &nbsp;</p>
								<p data-identifyelement="222">
									<strong
										><span style="background-color: rgb(247, 218, 100)"
											>NOTE: If your device is not found in the list below then we do NOT provide support for it.</span
										></strong
									>
								</p>
								<p data-identifyelement="223">
									<strong><br data-identifyelement="224" /></strong>
								</p>
								<p data-identifyelement="225"><strong data-identifyelement="226">Fire Products:</strong></p>
								<p data-identifyelement="227">FireTV Firestick Firestick4K Fire Cube</p>
								<p data-identifyelement="228">
									<router-link class="text-blue-500" :to="{ name: 'fireAmazonFireTvFireStickFireTv4K' }"> Click Here </router-link>
								</p>
								<p data-identifyelement="231"><br data-identifyelement="232" /></p>
								<p data-identifyelement="233"><strong data-identifyelement="234">Android TV:</strong></p>
								<p data-identifyelement="235">Nvidia-Shield/Xiomi-MiBox</p>
								<p data-identifyelement="236">
									<router-link class="text-blue-500" :to="{ name: 'androidTvNvidiaShieldXiomiMiBoxSonyAndroidTv' }"> Click Here </router-link>
								</p>
								<p data-identifyelement="239"><br data-identifyelement="240" /></p>
								<p data-identifyelement="241"><strong data-identifyelement="242">Android Mobile Devices:</strong></p>
								<p data-identifyelement="243">Samsung/LG/Sony/Google/Xiomi/Huawei/OnePlus/Motorolla/HTC/Nokia</p>
								<p data-identifyelement="244">
									<router-link class="text-blue-500" :to="{ name: 'androidMobileDevices' }"> Click Here </router-link>
								</p>
								<p data-identifyelement="247"><br data-identifyelement="248" /></p>
								<p data-identifyelement="249"><strong data-identifyelement="250">Android Box:</strong></p>
								<p data-identifyelement="251" dir="ltr">Any generic Android box</p>
								<p data-identifyelement="252">
									<router-link class="text-blue-500" :to="{ name: 'androidBoxGenericBoxes' }"> Click Here </router-link>
								</p>
								<p data-identifyelement="255">
									<strong data-identifyelement="256"><br data-identifyelement="257" /></strong>
								</p>
								<p data-identifyelement="258"><strong data-identifyelement="259">iOS devices:</strong><br data-identifyelement="260" />iPhone/iPad/AppleTV</p>
								<p data-identifyelement="261">
									<router-link class="text-blue-500" :to="{ name: 'iOsDevicesIPhoneIPadAppleTvOs13' }"> Click Here </router-link>
								</p>
								<p data-identifyelement="264"><br data-identifyelement="265" /></p>
								<p data-identifyelement="266"><strong data-identifyelement="267">PC:</strong></p>
								<p data-identifyelement="268">Laptop/PC/Mac book laptop/Web-interface</p>
								<p data-identifyelement="269">
									<router-link class="text-blue-500" :to="{ name: 'pcLaptopPcMacbookWebInterface' }"> Click Here </router-link>
								</p>
								<p data-identifyelement="272"><br data-identifyelement="273" /></p>
								<p>
									To setup and install on your devices please visit the setup guides<strong>&nbsp;</strong>
									<router-link :to="{ name: 'Home' }"><strong>here</strong></router-link>
								</p>
								<p><br /></p>
								<p>
									<span style="background-color: rgb(250, 197, 28)"
										><strong><u>Mag Box and Portals and xstream code and URL NOT SUPPORTED</u></strong></span
									>
								</p>
								<p>
									<span style="background-color: rgb(250, 197, 28)"
										><strong><u>ROKU NOT SUPPORTED</u></strong></span
									>
								</p>
								<p>
									<span style="background-color: rgb(250, 197, 28)"
										><strong><u>LGTV AND SAMSUNG TV NOT SUPPORTED</u></strong></span
									>
								</p>
								<p><br /></p>
								<p><br /></p>
								<p>
									<span style="font-size: 24px; background-color: rgb(255, 255, 255)"
										><strong><u>Connections</u></strong></span
									>
								</p>
								<p><br /></p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									You can watch up to 5 <strong style="box-sizing: border-box; font-weight: 700">streams&nbsp;</strong>(TV, Phone, Laptop, Computer etc) on
									<strong style="box-sizing: border-box; font-weight: 700">one (1) network at a time</strong>.
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									<br style="box-sizing: border-box" />
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									Example: &nbsp;John lives in Europe. John has 3 TVs and 2 cell phones in his family home. &nbsp;John can use all of these 5 devices at the same
									time on the same wifi in his home ✅&nbsp;
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									-John can <strong style="box-sizing: border-box; font-weight: 700">NOT&nbsp;</strong>✖️use his cellphone to watch a stream at work
									<strong style="box-sizing: border-box; font-weight: 700">while&nbsp;</strong>his kids are at home watching at the same time on the family TV.
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									-If John turns off his tv (streams) at his home he can ✅ then use his phone at work or on the go.
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									<br style="box-sizing: border-box" />
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									<br style="box-sizing: border-box" />
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									<br style="box-sizing: border-box" />
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									<strong style="box-sizing: border-box; font-weight: 700"><u style="box-sizing: border-box">In USA, Mexico &amp; Canada only</u></strong
									>, you can watch up to 5 streams on<strong style="box-sizing: border-box; font-weight: 700">&nbsp;t</strong
									><strong style="box-sizing: border-box; font-weight: 700">wo (2) different networks at a time</strong>.
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									<br style="box-sizing: border-box" />
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									<strong style="box-sizing: border-box; font-weight: 700">Example for USA, Canada and Mexico only</strong>. &nbsp;
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									<br style="box-sizing: border-box" />
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									John lives in USA. &nbsp;John has 2 tv's, 1 laptop, and 2 cellphones within his family. &nbsp;
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									John can ✅ use all of these 5 devices at the same time at home or have 4 tvs watching streams at home while his wife is at work or on the go on
									her cellphone (2 networks)
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									<br style="box-sizing: border-box" />
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									Example (4 tvs are streaming on the home wifi network and John's wife is watching a stream on her cell phone on the mobile data from work).
									&nbsp;
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									This IS allowed in USA, Canada and Mexico only. &nbsp;
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									<br style="box-sizing: border-box" />
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									<br style="box-sizing: border-box" />
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									Notes:
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									<span
										style="
											box-sizing: border-box;
											color: rgb(0, 0, 0);
											font-family: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
											font-size: 13px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>*There is <strong style="box-sizing: border-box; font-weight: 700">no geographical ip lock</strong>.</span
									>
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									*we do not offer discounts for less connections
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									*If you need more than the 5 devices you will need to purchase additional accounts using a different email. &nbsp;&nbsp;
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									*You can install the apps on as many devices as you want, that doesn't count as "watching", only "watching a stream" counts as 1 stream.
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									*Switching channels too quickly can cause the system to think you are using more devices than allowed and you will receive a message "device
									limit reached". &nbsp;To avoid this simply change channels a little slower and the system will reset itself automatically in a short amount of
									time.
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									*multi-view apps such as tivimate where multiple channels are watched count as separate streams&nbsp;
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									4 channels in multiview = 4 streams etc&nbsp;
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									<br style="box-sizing: border-box" />
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									For more information visit our FAQ &nbsp;<a :href="$store.state.brandInfo.faqLink" style="box-sizing: border-box; color: rgb(44, 92, 197)">{{
										$store.state.brandInfo.faqLink
									}}</a>
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									<br
										style="
											box-sizing: border-box;
											color: rgb(24, 50, 71);
											font-family: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
											font-size: 13px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/>
								</p>
							</article>

							<hr />
							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'WhatDevicesCanIWatchOnSupportedDevices',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
